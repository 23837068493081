import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import styles from './InstagramGrid.module.css';
import Feed from "react-instagram-authless-feed";

class InstagramGrid extends PureComponent {
	render() {
		const {} = this.props;
		//const {} = this.state;

		return (
			<div className={styles.Grid}>
				<Feed userName="kelpie.groenendael" className="Feed" classNameLoading="Loading" limit="12"/>
			</div>
		);
	}
}

InstagramGrid.propTypes = {};

InstagramGrid.defaultProps = {};

export default InstagramGrid;