import React, { PureComponent } from 'react';
import { Container,Row,Col } from "react-grid-system";
// import PropTypes from 'prop-types';
import styles from './Header.module.css';
class Header extends PureComponent {
	render() {
		// const {} = this.props;
		//const {} = this.state;
		return (
			<Container fluid>
				<Row>
					<Col offset={{md:1}}  md={10}>
						<div className={styles.Header}>
							<ul className={styles.Menu}>
								{/*<li><a href="https://www.instagram.com/kelpie.groenendael/">history</a></li>*/}
								<li><a href="https://www.instagram.com/kelpie.groenendael/">kelpie</a></li>
								{/*<li><a href="https://www.instagram.com/kelpie.groenendael/">kelpie</a></li>*/}
								{/*<li><a href="https://www.instagram.com/kelpie.groenendael/">links</a></li>*/}
								{/*<li><a href="https://www.instagram.com/kelpie.groenendael/">contacts</a></li>*/}
								{/*<li><a href="https://www.instagram.com/kelpie.groenendael/">ru</a></li>*/}
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;