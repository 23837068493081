import { InstagramGrid } from 'components/InstagramGrid';
import { Footer, Header } from 'containers';
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
// import PropTypes from 'prop-types';
import styles from './Landing.module.css';

class Landing extends PureComponent {

	render() {
		// const {} = this.props;
		//const {} = this.state;
		return (
			<div className={styles.Landing}>
				<Header/>
				<div className={styles.Content}>
					<Container fluid>
						<Row>
							<Col offset={{ md: 1 }} lg={10}>
								<InstagramGrid/>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer/>
			</div>
		);
	}
}

Landing.propTypes = {};

Landing.defaultProps = {};

export default Landing;